.gem_img {
  width: 25%;
}

.golden_bg {
  color: #c7a767;
}

.nav-tabs {
  background-color: transparent;
}

.golden_button {
  background: linear-gradient(
    90deg,
    rgb(179, 157, 113) 0%,
    rgb(132, 109, 61) 100%
  ) !important;
  color: #fff !important;
}
.layout-menu {
  overflow-y: scroll;
}

.wallet_new_img {
  width: 8rem !important;
}
.air_drops {
  overflow: hidden;
}
.target_img {
  width: 200px;
}

a.menu-link > i {
  margin-right: 0.5rem;
}

.menu-vertical .menu-sub .menu-link {
  padding-left: 1.875rem !important;
}
.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  display: none;
}

/* #pre-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.336);
}

    #pre-loader .loader-section {
      position: fixed;
      top: 0;
      width: 51%;
      height: 100%;
      z-index: 1000;
      background: #7575752f;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    #pre-loader .loader-section.section-left {
      left: 0;
    }

    #pre-loader .loader-section.section-right {
      right: 0;
    }

    #loader-logo {
    display: block;
    position: relative;
    left: calc(50% - 20em/2);
    top: 15%;
    width: 25vw;
    height: 80vh;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../assets/gif/loadwalk.gif");
    z-index: 1001;
    background-size: 30%} */
/* } */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loaded #pre-loader .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #pre-loader .loader-section.section-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-logo,
.loaded #loader-circle {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loaded #pre-loader {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

.no-js #pre-loader {
  display: none;
}

.tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
    any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
    right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #4b3f27;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
.load {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 0.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.balance span {
  color: #deb565;
  font-weight: bold;
}

.balance {
  border: 2px solid #d0aa5f;
  margin: 10px;
  padding: 6px;
  border-radius: 22px;
}

.loading-spinner {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0);
  top: 50%;
  width: 90px;
  height: 90px;
  z-index: 1;
  background: url(https://keepwalkking.io/static/media/log.5b87da400684ee9ebfef.png)
    center no-repeat;
  background-size: contain;
  /*animation: spinning 1.5s infinite ease-in-out;*/
}
.loading-spinner:after {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100%;
  margin: -15px;
  box-shadow: 0 4px 0 0 #8e7543;
  transition: all 1s linear;
  animation: lds-eclipse 1s linear infinite;
}
@keyframes spinning {
  0% {
    transform: translate(-50%, -50%) scale(1) translateZ(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1) translateZ(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1) translateZ(0);
  }
}
@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .leadership-titles {
    flex-direction: column;
  }
}
