@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;1,600;1,700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul.pagination li {
  padding: 20px;
}

.tab-bor table tbody tr td {
  padding: 6px;
  color: #ffff;
}

.tab-bor table tbody tr {
  border: 1px solid #9f989845;
}

.tab-bor table {
  width: 100%;
}

.tab-bor {
  text-align: center;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.bg-image {
  background-image: url("../img/gif/bg-login-signup.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: grid;
  place-items: center;
  font-family: "Nunito", sans-serif;
  justify-content: center;
}

.item-center {
  display: grid;
  place-items: center;
}

.otp_all_info {
  background: linear-gradient(10deg, #12120f -30%, #584a2d 100%);
  padding: 2rem;
  border-radius: 2rem;
  color: #fff;
  /* height: 650px; */
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.submit_button {
  text-decoration: none;
  position: relative;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  /* width: 9rem; */
  height: 3rem;
  /* line-height: 2rem; */
  text-align: center;
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
  /* margin-top: 50px; */
  cursor: pointer;
  padding: 0.5rem 5rem;
}

.google_athetication {
  text-decoration: none;
  position: relative;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  /* width: 9rem; */
  height: 3rem;
  line-height: 2rem;
  text-align: center;
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
  /* margin-top: 50px; */
  cursor: pointer;
  padding: 0.5rem 3rem;
}

.submit_button:hover {
  animation: ani 8s linear infinite;
  border: none;
}

.submit_button:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #bea675, #725c31, #725c31, #bea675);
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
}

.submit_button:hover::before {
  filter: blur(10px);
}

.an_account {
  text-decoration: none;
  border: 2px solid #b9a170;
  border-radius: 30px;
  cursor: pointer;
  padding: 0.8rem 3.6rem;
  font-weight: 600;
}

.Pin_set_form {
  background: #776e5eb7;
  padding: 2.5rem;
  border-radius: 1rem;
}

.Pin_set_form input {
  background-color: #584a2d;
  border: none;
  padding: 0.6rem;
  border-radius: 0.3rem;
  color: #f1eeee;
  outline: none;
  font-size: 12px;
}

.Pin_set_form input::placeholder {
  color: #b8b7b5;
  font-size: 12px;
}

.otp_code {
  color: #bea675;
}

.logo_img_otp {
  width: 50px;
}

.logo_img_otp img {
  width: 100%;
}

.otp-field input {
  width: 3rem;
  height: 3rem;
  font-size: 22.5px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #fff;
  background: transparent;
  font-weight: bold;
  color: #fff;
  outline: none;
  transition: all 0.1s;
}

.otp-field input:focus {
  border: 2px solid #54472b;
  box-shadow: 0 0 2px 2px #fff;
}

.message_box {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: #bea675;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.message_box i {
  color: #fff;
  font-size: 1.5rem;
}

.back_arrow {
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: #555350;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.back_arrow i {
  color: #fff;
  font-size: 1.5rem;
}

.user_email {
  color: #bea675;
}

.otp-field2 input {
  width: 3rem;
  height: 3rem;
  font-size: 22.5px;
  padding: 10px;
  text-align: center;
  /* border-radius: 5px; */
  background: transparent;
  font-weight: bold;
  color: #fff;
  outline: none;
  transition: all 0.1s;
  border: none;
  border-bottom: 1px solid #fff;
}

.reset_pin a {
  text-decoration: none;
}

@media (width < 768px) {
  /* SET-PIN page */
  .otp_all_info {
    width: auto;
  }

  .message_box {
    bottom: 10px;
    right: 10px;
  }

  /* PIN page */
  .logout_btn {
    top: 20px;
    right: 20px;
  }

  /* OTP page */
  .back_arrow {
    top: 10px;
    left: 10px;
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
  }

  .back_arrow i {
    font-size: 1.2rem;
  }
}

/* New Code */
.card {
  background: #3e3d3b;
}

.nav-align-top > .tab-content,
.nav-align-right > .tab-content,
.nav-align-bottom > .tab-content,
.nav-align-left > .tab-content {
  flex-shrink: 1;
  background-clip: padding-box;
  background: #32312e;
}

.swiper-container.swiper-card-advance-bg {
  box-shadow: 0 0.25rem 0.125rem #584a2d0d;
  /* background: linear-gradient(10deg, #22221c -30%, #77643d 100%); */
  background: linear-gradient(90deg, #57482a -30%, #836b38 100%);
}

.website-analytics-text-bg {
  background-color: #604e2c !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #856d3c !important;
  border-color: #29261d !important;
}

.btn-primary {
  background-color: #b09051 !important;
  border-color: #29261d !important;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
  background-color: #b09051;
  color: #fff;
}

.border-bottom {
  border-bottom: 1px solid #a3864a78 !important;
}

.border-top {
  border-top: 1px solid #a3864a78 !important;
}

a {
  color: #dbb364;
}

a:hover {
  color: #a3864a;
}

.waves-effect {
  background: #b09051;
  border: 1px solid #b09051;
}

.form-control {
  color: #dfdfdf;
  background-color: #282725;
  border: var(--bs-border-width) solid #77643d99;
}

.form-control:hover:not([disabled]):not([focus]) {
  border-color: #77643d;
}

.form-control:focus,
.form-select:focus {
  border-color: #77643d !important;
}

.form-control:focus {
  color: #fff;
  background-color: #77643d4f;
  border-color: #282725;
  box-shadow: 0 0.125rem 0.25rem rgb(119, 100, 61, 0.4);
}

select {
  background: #282725 !important;
  border-color: #77643d !important;
}

input::placeholder {
  color: #77643d !important;
  opacity: 0.6;
}

.facebook-div {
  background: linear-gradient(45deg, #1c70e1, #1c1c1c);
}

.card-title1 h5 {
  font-size: 12px !important;
  text-wrap: nowrap;
  color: #fff !important;
}

.card_main_img1 img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
}

.card-title1 p {
  font-size: 12px;
  padding-top: 0.2rem;
  color: #fff !important;
}

.card-title1 h6 {
  font-size: 11px;
  color: #fff !important;
}

.stepcard2.card {
  background: #892726;
  border-radius: 1.5rem;
  color: #fff !important;
}

.stepcard1.card {
  background: #2b650e;
  border-radius: 1.5rem;
  color: #fff !important;
}

.card.card-res {
  border-radius: 1.5rem !important;
}

.daily-zoom {
  background: linear-gradient(10deg, #1d1d18 -30%, #584a2d 100%);
  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
}

.daily-zoom-text a {
  color: #327a62 !important;
  text-decoration: underline !important;
}

.bg-lin {
  background: linear-gradient(0deg, #7a6432 20%, #18140e 100%);
  color: #fff !important;
}

.air_drops2 {
  overflow: hidden;
  height: 150px;
  text-align: center;
}

.air_drops2 img {
  width: 8rem;
}

.layout-navbar-fixed .layout-page:before {
  background: #282725 !important;
}

.bg-navbar-theme {
  background-color: #343434bd !important;
}

.bg-menu-theme {
  background-color: #383735 !important;
  color: #ffffff !important;
}

.layout-wrapper:not(.layout-horizontal)
  .bg-menu-theme
  .menu-inner
  .menu-item
  .menu-link {
  border-radius: 0.375rem;
}

.layout-menu-toggle.navbar-nav button i {
  color: #fff !important;
}

.menu:not(.menu-no-animation) .menu-link > :not(.menu-icon) {
  color: #fff;
}

a.menu-link:hover {
  text-decoration: none;
}

.modal-content {
  background-color: #302f2d;
}

.modal .btn-close {
  background-color: #302f2d;
}

.btn-secondary {
  background-color: #441313;
  border-color: #441313;
  border-radius: 1.5rem;
}

.bg-menu-theme .menu-inner .menu-item.open > .menu-link.menu-toggle,
.layout-menu-hover.layout-menu-collapsed
  .bg-menu-theme
  .menu-inner
  .menu-item.open
  > .menu-link.menu-toggle,
.bg-menu-theme .menu-inner .menu-item.active > .menu-link.menu-toggle,
.layout-menu-hover.layout-menu-collapsed
  .bg-menu-theme
  .menu-inner
  .menu-item.active
  > .menu-link.menu-toggle {
  background: rgb(87 73 45);
}

.app-brand-logo.demo {
  height: 60px;
}

.loading {
  text-align: center;
  height: 80vh;
  display: grid;
  place-content: center;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(84, 76, 55, 1) 0%,
    rgb(53 47 34) 100%
  ) !important;
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border-radius: 2rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
  box-shadow: none !important;
}

.nav-tabs .nav-link:not(.active):hover,
.nav-tabs .nav-link:not(.active):focus,
.nav-pills .nav-link:not(.active):hover,
.nav-pills .nav-link:not(.active):focus {
  color: #fff;
}

ul.dropdown-menu.dropdown-menu-end.show {
  background: #3e3d3b;
}

.dropdown-item {
  color: #ffffff;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #e1b765;
  background-color: #201b106b;
}

.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
  background-color: #dbb3643d;
}

.rsis-container .rsis-image {
  background-size: 100% 100% !important;
}

@media (max-width: 576px) {
  .singup2.bg-image {
    height: 125vh !important;
  }

  #dropdown-variation-demo .btn-group .text-truncate {
    width: 254px;
    position: relative;
  }

  #dropdown-variation-demo .btn-group .text-truncate::after {
    position: absolute;
    top: 45%;
    right: 0.65rem;
  }

  li.dashboard-title {
    flex-direction: column;
    justify-content: start !important;
    align-items: start !important;
    gap: 0.5rem;
  }

  .interchange {
    border-radius: 2rem;
    height: 2rem !important;
    width: 6rem !important;
    position: relative;
    top: 0.5rem !important;
  }

  .day_count {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
  }

  .leadership-titles {
    flex-direction: column;
  }

  ul.navbar-nav.navResponsiveReferal {
    margin-left: 0 !important;
    justify-content: space-between;
    width: 100%;
  }

  .PremiumPagination ul {
    /* justify-content: start !important; */
    flex-wrap: wrap;
  }
}

@media (min-width: 1280px) {
  aside#layout-menu + .layout-page nav#layout-navbar {
    width: calc(100% - 1.5rem * 2 - 16.25rem) !important;
  }
}

@media (max-width: 991px) {
  .premium-cards {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }

  .premium-cards .card {
    width: 48.5%;
  }

  ul#noanim-tab-example {
    justify-content: center !important;
  }

  .tab-content {
    padding: 1.5rem 0 !important;
  }
}

select.form-select option {
  background-color: #282725 !important;
}

option {
  background: #282725 !important;
  border: var(--bs-border-width) solid #77643d !important;
}

.para-color {
  color: #bebab3 !important;
}

ul.dropdown-menu.dropdown-menu-end.show {
  background: linear-gradient(
    180deg,
    rgba(51, 49, 50, 1) 0%,
    rgba(16, 16, 16, 1) 100%
  ) !important;
  border-radius: 1.5rem;
}

@media (width<425px) {
  .submit_button {
    padding: 0rem 2rem;
  }

  .an_account {
    padding: 0.8rem 2rem;
  }

  .balance {
    margin: 3px !important;
    padding: 5px !important;
    font-size: 9px !important;
    border: 1px solid #d0aa5f !important;
    background-color: transparent !important;
  }
}

.balance {
  margin: 5px;
  padding: 5px 10px;
  font-size: 12px;
  border: 1px solid #d0aa5f !important;
  background-color: transparent !important;
}

.balance:hover {
  margin: 5px;
  padding: 5px 10px;
  font-size: 12px;
  border: 1px solid #d0aa5f !important;
}


.rdt_TableCol, .rdt_Pagination, .rdt_TableRow, .rdt_Table {
  background-color: #333132 !important;
  color:white !important
}

#pagination-first-page {
  color: white !important;
}
